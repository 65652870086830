import type { Blending, Texture } from 'three';

export const TEXTURE_SIZE = 2048;

export interface TextureLayer {
    layerNumber: number;
    blendType: Blending;
    textureMapName: string; // The map on the mesh this applies to (e.g., 'diffuseMap', 'normalMap')
    opacity: number;
    texture: Texture | null; // Three.js texture object
    canvas: HTMLCanvasElement | null; // Corresponding canvas element
    context: CanvasRenderingContext2D | null; // Canvas context
}
